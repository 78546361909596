<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 137.56 207.73">
        <g data-name="Layer 1">
            <rect width="129.56" height="199.73" x="4" y="4" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" rx="22.01"/>
            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" d="M29.04 34.32h17.87M60.02 34.32h17.87M90.65 34.32h17.87M29.04 54.19h17.87M60.02 54.19h17.87M90.65 54.19h17.87M29.04 74.06h17.87M60.02 74.06h17.87M90.65 74.06h17.87"/>
            <circle cx="68.78" cy="138.64" r="35.79" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/>
            <path d="M78.12 132.14a9.35 9.35 0 00-9.61-9.34 9.46 9.46 0 00-9.07 9.2 9.34 9.34 0 006.63 9.1v10.7a2.71 2.71 0 002.71 2.71 2.71 2.71 0 002.71-2.71v-10.7a9.33 9.33 0 006.63-8.96z"/>
        </g>
    </svg>
</template>
