<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 480">
    <path
      d="M472 272h-8v-24a39.999 39.999 0 00-24-36.608V88a8 8 0 00-4.8-7.328A31.666 31.666 0 00440 64c0-17.673-14.327-32-32-32-17.673 0-32 14.327-32 32a31.675 31.675 0 004.448 16H99.552A31.675 31.675 0 00104 64c0-17.673-14.327-32-32-32S40 46.327 40 64a31.666 31.666 0 004.8 16.672A8 8 0 0040 88v123.392A39.999 39.999 0 0016 248v24H8a8 8 0 00-8 8v112a8 8 0 008 8h8v40a8 8 0 008 8h32a8 8 0 008-8v-40h352v40a8 8 0 008 8h32a8 8 0 008-8v-40h8a8 8 0 008-8V280a8 8 0 00-8-8zM408 48c8.837 0 16 7.163 16 16s-7.163 16-16 16-16-7.163-16-16 7.163-16 16-16zM72 48c8.837 0 16 7.163 16 16s-7.163 16-16 16-16-7.163-16-16 7.163-16 16-16zM56 96h368v112h-32.208A39.583 39.583 0 00400 184v-16c-.026-22.08-17.92-39.974-40-40h-64c-22.08.026-39.974 17.92-40 40v16a39.583 39.583 0 008.208 24h-48.416A39.583 39.583 0 00224 184v-16c-.026-22.08-17.92-39.974-40-40h-64c-22.08.026-39.974 17.92-40 40v16a39.583 39.583 0 008.208 24H56V96zm328 72v16c0 13.255-10.745 24-24 24h-64c-13.255 0-24-10.745-24-24v-16c0-13.255 10.745-24 24-24h64c13.255 0 24 10.745 24 24zm-176 0v16c0 13.255-10.745 24-24 24h-64c-13.255 0-24-10.745-24-24v-16c0-13.255 10.745-24 24-24h64c13.255 0 24 10.745 24 24zM32 248c0-13.255 10.745-24 24-24h368c13.255 0 24 10.745 24 24v24H32v-24zm16 184H32v-32h16v32zm400 0h-16v-32h16v32zm16-48H16v-96h448v96z"
    />
    <path
      d="M72 352H40a8 8 0 000 16h32a8 8 0 000-16zm368 0H104a8 8 0 000 16h336a8 8 0 000-16z"
    />
  </svg>
</template>
