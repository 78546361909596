<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="15.936" height="15.936" viewBox="0 0 15.936 15.936">
    <g transform="translate(1.186 0.75)">
      <path d="M17,9.611a6.518,6.518,0,0,1-.7,2.956,6.611,6.611,0,0,1-5.911,3.656,6.518,6.518,0,0,1-2.956-.7L3,17l1.478-4.433a6.518,6.518,0,0,1-.7-2.956A6.611,6.611,0,0,1,7.433,3.7,6.518,6.518,0,0,1,10.389,3h.389A6.6,6.6,0,0,1,17,9.222Z" transform="translate(-3 -3)" fill="none" stroke="#2c3e50" stroke-linecap="round" stroke-width="1.5"/>
      <g transform="translate(2.5 5.75)">
        <g>
          <circle cx="0.75" cy="0.75" r="0.75" transform="translate(1)" fill="#2c3e50"/>
          <circle cx="0.75" cy="0.75" r="0.75" transform="translate(4)" fill="#2c3e50"/>
          <circle cx="0.75" cy="0.75" r="0.75" transform="translate(7)" fill="#2c3e50"/>
        </g>
      </g>
    </g>
  </svg>
</template>
