<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 208.17 195.3">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <g>
                    <path d="M197,71.49a96.36,96.36,0,0,0-183.93.19" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/>
                    <polyline points="117.61 98.32 117.61 38.95 67.29 98.32 92.45 98.32" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/>
                    <polyline points="92.56 98.32 92.56 157.7 142.88 98.32 117.72 98.32" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/>
                    <polyline points="34.34 63.28 13.09 71.68 4 51.13" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/>
                    <path d="M11.16,123.81a96.36,96.36,0,0,0,183.92-.19" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/>
                    <polyline points="173.84 132.02 195.08 123.62 204.18 144.18" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/>
                </g>
            </g>
        </g>
    </svg>
</template>
