<template>
    <svg
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g class="fill-current">
                <path d="M14,16 L14,20 L16,20 L16,16 L20,16 L20,14 L6,14 L6,0 L4,0 L4,4 L0,4 L0,6 L4,6 L4,14.0059397 C4,15.1054862 4.8927712,16 5.99406028,16 L14,16 Z M14,13 L14,6 L7,6 L7,4 L14.0059397,4 C15.1072288,4 16,4.89451376 16,5.99406028 L16,13 L14,13 Z" id="Combined-Shape"></path>
            </g>
        </g>
    </svg>
</template>
