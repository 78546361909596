<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19.93" height="10.823" viewBox="0 0 19.93 10.823">
    <g transform="translate(1.054 1.054)">
      <path class="a" d="M6.8,13.764l1.8,2.255L15.822,7" transform="translate(2 -7)"
      fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5px"/>
      <path class="a" d="M5,11.509l3.607,4.509L15.822,7" transform="translate(-5 -7)"
      fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5px"/>
    </g>
  </svg>
</template>
