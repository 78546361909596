<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.65 202.44">
        <g data-name="Layer 2">
            <g stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" data-name="Layer 1">
                <circle cx="50.37" cy="152.07" r="22.17"/>
                <path fill="none" d="M96.74 152.07A46.37 46.37 0 1127.09 112V27.28a23.28 23.28 0 0146.56 0V112a46.35 46.35 0 0123.09 40.07zM50.37 152.07V52.79M92.05 49.89h26.6M92.05 69.55h26.6M92.05 89.22h26.6"/>
            </g>
        </g>
    </svg>
</template>
