<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
    <path
      d="M91.021 82.078l-11.909-1.1-.412-5.9a27.055 27.055 0 0012.387-21.131l.019-.312a7.746 7.746 0 006.724-7.675 7.785 7.785 0 00-5.789-7.48l.006-.1a4.727 4.727 0 002.053-3.742l.755-21.87A7.749 7.749 0 0087.11 4.75H40.89a7.749 7.749 0 00-7.745 8.018l.755 21.871a4.737 4.737 0 002.139 3.812 7.761 7.761 0 00-5.869 7.509 7.749 7.749 0 006.815 7.688l.018.3A27.094 27.094 0 0049.3 75l-.414 5.974-11.909 1.1A25.624 25.624 0 0013.6 107.71v13.79a1.749 1.749 0 001.75 1.75h97.3a1.749 1.749 0 001.75-1.75v-13.79a25.624 25.624 0 00-23.379-25.632zM38.648 35.724a1.248 1.248 0 01-1.25-1.207l-.044-1.3h53.292l-.044 1.3a1.248 1.248 0 01-1.25 1.208zM94.33 45.96a4.208 4.208 0 01-3 4.044l.489-7.923a4.208 4.208 0 012.511 3.879zM37.833 9.547a4.219 4.219 0 013.057-1.3h46.22a4.248 4.248 0 014.247 4.4l-.59 17.074H37.233l-.59-17.074a4.215 4.215 0 011.19-3.1zm-2.922 39.41a4.186 4.186 0 01-1.241-3 4.258 4.258 0 012.6-3.915l.492 7.986a4.173 4.173 0 01-1.851-1.071zm5.589 4.775l-.9-14.508h48.887l-.894 14.508a23.592 23.592 0 01-47.1 0zM64.04 79.38a27.055 27.055 0 0011.28-2.455l.361 5.173c-1.784 2.594-5.81 4.8-11.621 6.449-5.964-1.675-10-3.925-11.736-6.538l.357-5.134A26.911 26.911 0 0064.04 79.38zM17.1 107.71a22.14 22.14 0 0120.2-22.147l12.436-1.152c1.617 2.172 4.259 4.044 7.893 5.6q-3.327.6-7.13 1a7.72 7.72 0 00-6.92 7.7v21.039H17.1zm93.8 12.04H47.08V98.716a4.229 4.229 0 013.786-4.224c10.034-1.056 22.679-3.673 27.455-10.076L90.7 85.563a22.14 22.14 0 0120.2 22.147z"
    />
    <path
      d="M21.649 103.96a1.737 1.737 0 00-1.928 1.55 20.407 20.407 0 00-.121 2.2v2.577a1.75 1.75 0 003.5 0v-2.577a16.982 16.982 0 01.1-1.826 1.749 1.749 0 00-1.551-1.924zm72.056-2.21h-12a1.75 1.75 0 100 3.5h12a1.75 1.75 0 000-3.5zM64 95.75a4.75 4.75 0 104.75 4.75A4.756 4.756 0 0064 95.75zm0 6a1.25 1.25 0 111.25-1.25 1.252 1.252 0 01-1.25 1.25zm0 6a4.75 4.75 0 104.75 4.75 4.756 4.756 0 00-4.75-4.75zm0 6a1.25 1.25 0 111.25-1.25 1.252 1.252 0 01-1.25 1.25z"
    />
  </svg>
</template>
