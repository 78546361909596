<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 197.91 197.91">
        <g data-name="Layer 2">
            <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" data-name="Layer 1">
                <circle cx="98.96" cy="98.96" r="94.96"/>
                <path d="M137.27 98.96l-58.63 37.32V61.63l58.63 37.33z"/>
            </g>
        </g>
    </svg>
</template>
