<template>
<svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.911 14.457">
  <g fill="none" stroke="#bababa" stroke-miterlimit="10" stroke-width="1.5" transform="translate(-1.75 -1.75)">
    <path d="M9.48 15.457H2.5V4.764h13.847" data-name="Path 47"/>
    <path d="M16.344 4.764h7.433v10.693h-7.433" data-name="Path 48"/>
    <circle cx="2.968" cy="2.968" r="2.968" data-name="Ellipse 61" transform="translate(9.966 7.138)"/>
    <path d="M5.813 2.5h20.1v10.86" data-name="Path 49"/>
    <path d="M2.5 8.383a3.619 3.619 0 003.621-3.619" data-name="Path 50"/>
    <path d="M6.121 15.456A3.619 3.619 0 002.5 11.837" data-name="Path 51"/>
    <path d="M23.779 11.837a3.619 3.619 0 00-3.619 3.619" data-name="Path 52"/>
    <path d="M20.16 4.764a3.619 3.619 0 003.619 3.619" data-name="Path 53"/>
  </g>
</svg>
</template>