<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.625"
      y="0.625"
      width="12.75"
      height="12.75"
      rx="2.375"
      stroke="#999999"
      stroke-width="1.25"
    />
    <path d="M7 1V13" stroke="#999999" stroke-width="1.25" />
    <path d="M1 8L7 8" stroke="#999999" stroke-width="1.25" />
    <path d="M7 6H13" stroke="#999999" stroke-width="1.25" />
  </svg>
</template>
