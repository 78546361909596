<template>
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.057 22.963">
  <g fill="none" stroke="#3498db" stroke-miterlimit="10" stroke-width="1.5" data-name="Group 85" transform="translate(-15 -24.378)">
    <circle cx="2.951" cy="2.951" r="2.951" data-name="Ellipse 59" transform="translate(19.123 25.128)"/>
    <path d="M22.412 33.206h-3.738l-3.055 4.472" data-name="Path 42"/>
    <path d="M25.64 38.102v8.49h-7.134v-8.49" data-name="Path 43"/>
    <circle cx="2.951" cy="2.951" r="2.951" data-name="Ellipse 60" transform="translate(32.032 25.128)"/>
    <path d="M34.645 33.206h3.738l3.055 4.472" data-name="Path 44"/>
    <path d="M35.321 33.206h-3.738l-3.055 4.472-3.055-4.472h-3.738" data-name="Path 45"/>
    <path d="M38.549 38.102v8.49h-7.134v-8.49" data-name="Path 46"/>
  </g>
</svg>
</template>