<template>
<svg width="40" height="40" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="atoms/icons/folder">
    <path id="Rectangle 1007" d="M4 9.375H3.375V10V14C3.375 14.8975 4.10254 15.625 5 15.625H15C15.8975 15.625 16.625 14.8975 16.625 14V10V9.375H16H4Z" stroke="#999999" stroke-width="1.25"/>
    <g id="Rectangle 1008">
    <mask id="path-2-outside-1_2556_107709" maskUnits="userSpaceOnUse" x="2" y="3" width="16" height="14" fill="black">
    <rect fill="white" x="2" y="3" width="16" height="14"/>
    <path d="M4 6C4 5.44772 4.44772 5 5 5L6.16667 5C6.6269 5 7 5.3731 7 5.83333V5.83333C7 6.29357 7.3731 6.66667 7.83333 6.66667H15C15.5523 6.66667 16 7.11438 16 7.66667V14C16 14.5523 15.5523 15 15 15H5C4.44772 15 4 14.5523 4 14L4 6Z"/>
    </mask>
    <path d="M14.75 7.66667V14H17.25V7.66667H14.75ZM15 13.75H5V16.25H15V13.75ZM5.25 14V6H2.75V14H5.25ZM5 6.25H6.16667V3.75H5V6.25ZM7.83333 7.91667H15V5.41667H7.83333V7.91667ZM5.75 5.83333C5.75 6.98393 6.68274 7.91667 7.83333 7.91667V5.41667C8.06345 5.41667 8.25 5.60321 8.25 5.83333H5.75ZM6.16667 6.25C5.93655 6.25 5.75 6.06345 5.75 5.83333H8.25C8.25 4.68274 7.31726 3.75 6.16667 3.75V6.25ZM5 13.75C5.13807 13.75 5.25 13.8619 5.25 14H2.75C2.75 15.2426 3.75736 16.25 5 16.25V13.75ZM14.75 14C14.75 13.8619 14.8619 13.75 15 13.75V16.25C16.2426 16.25 17.25 15.2426 17.25 14H14.75ZM17.25 7.66667C17.25 6.42403 16.2426 5.41667 15 5.41667V7.91667C14.8619 7.91667 14.75 7.80474 14.75 7.66667H17.25ZM5.25 6C5.25 6.13807 5.13807 6.25 5 6.25V3.75C3.75736 3.75 2.75 4.75736 2.75 6H5.25Z" fill="#999999" mask="url(#path-2-outside-1_2556_107709)"/>
    </g>
    </g>
</svg>
</template>