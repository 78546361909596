<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <g transform="translate(-1.002 -1)">
      <rect class="a" width="14" height="14" transform="translate(1.002 1)" fill="#fff" opacity="0"/>
      <path class="b" d="M-13913,14772.507h-4v4" transform="translate(-13910.998 14778.506) rotate(180)" fill="none" stroke="currentColor"/>
      <path class="b" d="M-13913,14772.507h-4v4" transform="translate(13927.002 -14762.5)" fill="none" stroke="currentColor"/>
      <path class="b" d="M-13913,14772.507h-4v4" transform="translate(-14762.506 -13910.992) rotate(-90)" fill="none" stroke="currentColor"/>
      <path class="b" d="M-13913,14772.507h-4v4" transform="translate(14778.51 13927.005) rotate(90)" fill="none" stroke="currentColor"/>
    </g>
  </svg>
</template>
