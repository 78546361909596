<template>
   <svg
        class="ban-alt w-6 h-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 25 25"
        xmlns="http://www.w3.org/2000/svg"
    >

        <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636">
        </path>

   </svg>
</template>
