<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <g transform="translate(-1.5 -1.5)">
      <circle fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.25px" cx="6.375" cy="6.375" r="6.375" transform="translate(2.125 2.125)"/>
      <line fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.25px" y2="2.833" transform="translate(8.5 5.666)"/>
      <line fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.25px" x2="0.007" transform="translate(8.5 11.334)"/>
    </g>
  </svg>
</template>
