<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 197.91 197.91">
        <g data-name="Layer 2">
            <g fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" data-name="Layer 1">
                <path d="M135.1 45.17a63.78 63.78 0 0127 52.2c0 35.18-28.25 63.69-63.09 63.69s-63.1-28.51-63.1-63.69a63.78 63.78 0 0127-52.2M98.96 91.35V33.69"/>
                <circle cx="98.96" cy="98.96" r="94.96"/>
            </g>
        </g>
    </svg>
</template>
