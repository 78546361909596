<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81.51 68.643">
        <g fill="#E9E9E9">
            <path data-name="Path 170" d="M34.68 7.83L19.019 0 3.358 7.83l15.661 7.83z"/>
            <path data-name="Path 171" d="M59.774 44.831l-16.3 8.151v15.661l16.3-8.151z"/>
            <path data-name="Path 172" d="M46.83 7.83l15.661 7.83 15.661-7.83L62.491 0z"/>
            <path data-name="Path 173" d="M65.208 36.039l16.3-8.151V12.227l-16.3 8.151z"/>
            <path data-name="Path 174" d="M38.038 12.227l-16.3 8.151v15.661l16.3-8.151z"/>
            <path data-name="Path 175" d="M59.774 20.378l-16.3-8.151v15.661l16.3 8.151z"/>
            <path data-name="Path 176" d="M56.413 40.434l-15.658-7.83-15.661 7.83 15.661 7.83z"/>
            <path data-name="Path 177" d="M21.736 60.492l16.3 8.151V52.982l-16.3-8.151z"/>
            <path data-name="Path 178" d="M0 27.888l16.3 8.151V20.378L0 12.227z"/>
        </g>
    </svg>
</template>