<template>
    <svg
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g class="fill-current">
                <polygon id="Combined-Shape" points="10 13 18 13 18 7 10 7 10 2 2 10 10 18 10 13"></polygon>
            </g>
        </g>
    </svg>
</template>
