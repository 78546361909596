<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 480 480"
    fill="currentColor"
  >
    <path
      d="M472 440h-48V144a8 8 0 00-8-8H280V32a8 8 0 00-8-8H64a8 8 0 00-8 8v408H8a8 8 0 000 16h464a8 8 0 000-16zm-208 0H72V40h192v400zm144 0H280V152h128v288z"
    />
    <path
      d="M320 232h48a8 8 0 008-8v-48a8 8 0 00-8-8h-48a8 8 0 00-8 8v48a8 8 0 008 8zm8-48h32v32h-32v-32zM320 312h48a8 8 0 008-8v-48a8 8 0 00-8-8h-48a8 8 0 00-8 8v48a8 8 0 008 8zm8-48h32v32h-32v-32zM320 392h48a8 8 0 008-8v-48a8 8 0 00-8-8h-48a8 8 0 00-8 8v48a8 8 0 008 8zm8-48h32v32h-32v-32zM184 232h48a8 8 0 008-8v-48a8 8 0 00-8-8h-48a8 8 0 00-8 8v48a8 8 0 008 8zm8-48h32v32h-32v-32zM184 152h48a8 8 0 008-8V96a8 8 0 00-8-8h-48a8 8 0 00-8 8v48a8 8 0 008 8zm8-48h32v32h-32v-32zM184 312h48a8 8 0 008-8v-48a8 8 0 00-8-8h-48a8 8 0 00-8 8v48a8 8 0 008 8zm8-48h32v32h-32v-32zM184 392h48a8 8 0 008-8v-48a8 8 0 00-8-8h-48a8 8 0 00-8 8v48a8 8 0 008 8zm8-48h32v32h-32v-32zM104 232h48a8 8 0 008-8v-48a8 8 0 00-8-8h-48a8 8 0 00-8 8v48a8 8 0 008 8zm8-48h32v32h-32v-32zM104 152h48a8 8 0 008-8V96a8 8 0 00-8-8h-48a8 8 0 00-8 8v48a8 8 0 008 8zm8-48h32v32h-32v-32zM104 312h48a8 8 0 008-8v-48a8 8 0 00-8-8h-48a8 8 0 00-8 8v48a8 8 0 008 8zm8-48h32v32h-32v-32zM104 392h48a8 8 0 008-8v-48a8 8 0 00-8-8h-48a8 8 0 00-8 8v48a8 8 0 008 8zm8-48h32v32h-32v-32z"
    />
  </svg>
</template>
