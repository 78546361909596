<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14.979" height="18.349" viewBox="0 0 14.979 18.349">
    <g transform="translate(0.75 0.75)">
      <path class="a" fill="none" stroke="currentColor" stroke-width="1.5px"
            d="M12.424,2H5.685A1.685,1.685,0,0,0,4,3.685V17.164a1.69,1.69,0,0,0,1.685,1.685H15.794a1.685,1.685,0,0,0,1.685-1.685V7.055Z"
            transform="translate(-4 -2)"/>
      <path class="a" fill="none" stroke="currentColor" stroke-width="1.5px" d="M13.055,3V7.212h4.212M14.74,11.424H8m6.74,3.37H8m1.685-6.74H8"
            transform="translate(-4.63 -2.158)"/>
    </g>
  </svg>
</template>
