<template>
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 216 216" fill="currentColor">
  <rect width="5.99" height="18.4" x="121.97" y="108.36" rx="2.99"/>
  <rect width="5.99" height="18.4" x="87.86" y="108.36" rx="2.99"/>
  <path d="M198.34 67.06H17.66a4 4 0 00-4 4v100.65a4 4 0 004 4h180.68a4 4 0 004-4V71.06a4 4 0 00-4-4zm-176.68 8h172.68v12.4H21.66zm0 20.4h81.92v72.25H21.66zm172.68 72.25h-81.92V95.46h81.92z"/>
  <path d="M111.33 55.85a2.77 2.77 0 00-1.82-1.24h-.37c-1.73 0-16.89-.52-16.89-18.32a2.59 2.59 0 00-5.17 0c0 17.8-15.09 18.32-16.81 18.32a2.49 2.49 0 00-1.56.53 1.72 1.72 0 00-.5.49l-.18.17-.1.22a2.71 2.71 0 000 2.42 2.67 2.67 0 002.42 1.41c1.72 0 16.81.52 16.81 18.32a2.59 2.59 0 005.17 0c0-17.8 15.09-18.32 16.81-18.32a2.74 2.74 0 002.2-1.18l.14-.23a2.51 2.51 0 00-.15-2.59z"/>
  <path fill="#fff" d="M89.68 46.76a19.43 19.43 0 0010.59 10.48 19.17 19.17 0 00-10.54 10.37 19.38 19.38 0 00-10.56-10.48 19.1 19.1 0 0010.51-10.37"/>
  <path d="M75.93 31.26c-8.25 0-8.49-8.37-8.49-9.32a2.59 2.59 0 10-5.17 0c0 .95-.23 9.32-8.57 9.32h-.21a2.57 2.57 0 00-2.29 2.57 2.62 2.62 0 002.59 2.58c1.41 0 8.48.45 8.48 9.32a2.61 2.61 0 002.47 2.58h.22a2.32 2.32 0 001.49-.56 2.58 2.58 0 00.91-2c0-1 .23-9.32 8.48-9.32h.09a2.63 2.63 0 000-5.25z"/>
  <path fill="#fff" d="M64.87 29.7a11.83 11.83 0 004.19 4.14l-.1.06a11.75 11.75 0 00-4.11 4 11.51 11.51 0 00-4.1-4.08 11.7 11.7 0 004.12-4.12"/>
  <path d="M60.83 60.12c-8.25 0-8.49-8.37-8.49-9.33a2.58 2.58 0 00-5.16 0c0 1-.24 9.33-8.57 9.33h-.21a2.57 2.57 0 00-2.29 2.57 2.61 2.61 0 002.58 2.58c1.42 0 8.49.45 8.49 9.32a2.63 2.63 0 002.36 2.57 2 2 0 00.42 0 2.1 2.1 0 001.26-.42 2.6 2.6 0 001-2.12c0-1 .24-9.32 8.57-9.32a2.62 2.62 0 000-5.24z"/>
  <path fill="#fff" d="M49.78 58.56A11.94 11.94 0 0054 62.69l-.1.06a11.85 11.85 0 00-4.1 4 11.67 11.67 0 00-4.1-4.08 11.7 11.7 0 004.12-4.12"/>
</svg>
</template>