<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512.228 512.228"
    fill="currentColor"
  >
    <path
      d="M500.718 256.426c-17.831-34.679-53.593-56.441-92.587-56.34a103.37 103.37 0 00-32.432 5.248l-42.624-85.248h67.056a8 8 0 010 16h-32a8 8 0 000 16h32c13.255 0 24-10.745 24-24s-10.745-24-24-24h-80a8.001 8.001 0 00-7.2 11.576l10.256 20.424H181.074l-8-16h43.056a8 8 0 000-16h-80a8 8 0 000 16h19.056l12 24-30.624 61.248C82.007 187.366 23.216 217.025 5.248 271.58c-17.968 54.555 11.691 113.346 66.246 131.314 54.555 17.968 113.346-11.691 131.314-66.246a104.017 104.017 0 004.914-24.562h48.408a7.917 7.917 0 001.92-.248l.168-.04c.184-.048.344-.152.528-.208.324-.096.642-.21.952-.344.048 0 .072-.064.112-.088.04-.024.224-.112.328-.176.232-.151.457-.314.672-.488.345-.242.671-.51.976-.8l.312-.32c.12-.136.192-.288.304-.432.112-.144.376-.408.528-.648.064-.112.096-.24.152-.352.056-.112.144-.176.2-.28l72.848-145.688 24.8 49.6c-50.987 26.216-71.068 88.801-44.852 139.787 26.216 50.987 88.801 71.068 139.787 44.852 50.987-26.216 71.068-88.801 44.853-139.787zM104.13 392.086c-48.601.068-88.055-39.276-88.123-87.877-.068-48.601 39.276-88.055 87.877-88.123a87.992 87.992 0 0125.439 3.72L88.97 300.51a8 8 0 007.16 11.576h95.592c-4.171 45.278-42.122 79.939-87.592 80zm4.944-96l35.128-70.248a88.056 88.056 0 0147.52 70.248h-82.648zm98.648 0a103.999 103.999 0 00-56.392-84.544l24.8-49.6 67.056 134.144h-35.464zm48.408-9.888l-67.056-134.112h134.112L256.13 286.198zm177.439 102.168a87.992 87.992 0 01-25.439 3.72c-48.566.04-87.968-39.298-88.008-87.864-.027-33.062 18.493-63.345 47.936-78.384l40.912 81.824a8.004 8.004 0 0014.32-7.152l-40.352-80.704c46.547-13.981 95.614 12.418 109.595 58.965 13.982 46.546-12.417 95.614-58.964 109.595z"
    />
    <path
      d="M104.13 320.086h-8a8 8 0 000 16h8a8 8 0 000-16zM136.13 320.086h-8a8 8 0 000 16h8a8 8 0 000-16zM168.13 320.086h-8a8 8 0 000 16h8a8 8 0 000-16z"
    />
  </svg>
</template>
