<template>
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="10" class="primary"/>
        <path class="secondary" d="M13 11h4a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4z"/>
    </svg>
</template>
<style scoped>
	.primary{
		fill:none;
		stroke: currentColor;
		stroke-width: 1px;
	}
	.secondary{
		fill: currentColor;
	}
</style>
