<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19.5" height="19.5" viewBox="0 0 19.5 19.5">
    <g transform="translate(0.75 0.75)">
      <g transform="translate(-3 -3)">
        <circle class="a" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#840000" stroke-width="1.5px"/>
        <line class="b" y2="5" transform="translate(12 7)" fill="none" stroke="#840000" stroke-width="1.5px" stroke-linecap="round"/>
        <line class="b" y2="1" transform="translate(12 16)" fill="none" stroke="#840000" stroke-width="1.5px" stroke-linecap="round"/>
      </g>
    </g>
  </svg>
</template>
