<template>
  <svg id="Component_83_1" data-name="Component 83 – 1" xmlns="http://www.w3.org/2000/svg" width="14" height="14"
       viewBox="0 0 14 14">
    <g id="Group_1029" data-name="Group 1029" transform="translate(-1.002 -1)">
      <rect id="Rectangle_1133" data-name="Rectangle 1133" width="14" height="14" transform="translate(1.002 1)"
            fill="#fff" opacity="0"/>
      <path id="Path_252" data-name="Path 252" d="M-13913,14772.507h-4v4" transform="translate(13919 -14770.507)"
            fill="none" stroke="currentColor" stroke-width="1"/>
      <path id="Path_255" data-name="Path 255" d="M-13913,14772.507h-4v4"
            transform="translate(-13902.996 14786.514) rotate(180)" fill="none" stroke="currentColor" stroke-width="1"/>
      <path id="Path_253" data-name="Path 253" d="M-13913,14772.507h-4v4"
            transform="translate(14786.508 13919.006) rotate(90)" fill="none" stroke="currentColor" stroke-width="1"/>
      <path id="Path_254" data-name="Path 254" d="M-13913,14772.507h-4v4"
            transform="translate(-14770.504 -13902.992) rotate(-90)" fill="none" stroke="currentColor" stroke-width="1"/>
    </g>
  </svg>

</template>
