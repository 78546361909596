<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path d="M0,0H16V16H0Z" fill="none"/>
    <g transform="translate(-1 -0.516)">
      <ellipse cx="2.192" cy="2.19" rx="2.192" ry="2.19" transform="translate(6.898 7.514)" fill="none" stroke="#3498db" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
      <path d="M4.515,6.273H6.03A1.515,1.515,0,0,0,7.545,4.757.757.757,0,0,1,8.3,4H9.817m6.817,5.3v5.3A1.515,1.515,0,0,1,15.12,16.12H4.515A1.515,1.515,0,0,1,3,14.6V7.787A1.515,1.515,0,0,1,4.515,6.273" transform="translate(-0.728 -0.97)" fill="none" stroke="#3498db" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
      <line x2="4.383" transform="translate(11.281 4.707)" fill="none" stroke="#3498db" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
      <line y2="4.383" transform="translate(13.474 2.515)" fill="none" stroke="#3498db" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
    </g>
  </svg>
</template>
