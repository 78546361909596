<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    fill="currentColor"
  >
    <path
      d="M501.333 21.333H10.667C4.779 21.333 0 26.112 0 32v277.333C0 315.221 4.779 320 10.667 320h170.667c5.888 0 10.667-4.779 10.667-10.667v-42.667c0-5.888-4.779-10.667-10.667-10.667s-10.667 4.779-10.667 10.667v32H21.333V42.667h149.333V48c0 5.888 4.779 10.667 10.667 10.667S192 53.888 192 48v-5.333h298.667v128H288c-5.888 0-10.667 4.779-10.667 10.667V224c0 5.888 4.779 10.667 10.667 10.667s10.667-4.779 10.667-10.667v-32h192v170.667H352c-5.888 0-10.667 4.779-10.667 10.667v96h-320v-32c0-5.888-4.779-10.667-10.667-10.667S0 431.445 0 437.333V480c0 5.888 4.779 10.667 10.667 10.667H352c5.888 0 10.667-4.779 10.667-10.667v-96h138.667c5.888 0 10.667-4.779 10.667-10.667V32c-.001-5.888-4.78-10.667-10.668-10.667z"
    />
    <path
      d="M181.333 132.267A10.657 10.657 0 0 0 192 121.6V97.067c0-5.888-4.779-10.667-10.667-10.667s-10.667 4.757-10.667 10.667V121.6a10.658 10.658 0 0 0 10.667 10.667zM160 170.667c-5.888 0-10.667 4.779-10.667 10.667S154.112 192 160 192h21.333c5.888 0 10.667-4.779 10.667-10.667v-10.667c0-5.888-4.779-10.667-10.667-10.667s-10.667 4.779-10.667 10.667H160zM96 170.667c-5.888 0-10.667 4.779-10.667 10.667S90.112 192 96 192h21.333c5.888 0 10.667-4.779 10.667-10.667s-4.779-10.667-10.667-10.667H96zM32 192h21.333C59.221 192 64 187.221 64 181.333s-4.779-10.667-10.667-10.667H32c-5.888 0-10.667 4.779-10.667 10.667S26.112 192 32 192z"
    />
  </svg>
</template>
