<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198.9 199.96">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <g>
                    <g>
                        <circle cx="99.46" cy="142.6" r="7.22" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/>
                        <path d="M79.8,128a24.51,24.51,0,0,1,39.31,0" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/>
                        <path d="M67.56,114.6a42.44,42.44,0,0,1,63.79,0" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/>
                        <path d="M55.38,98.52a62.32,62.32,0,0,1,88.15,0" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/>
                    </g>
                    <polyline points="16.53 67.08 16.53 195.96 184.84 195.96 184.84 68.08" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/>
                    <line x1="99.45" y1="4" x2="194.9" y2="75.39" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/>
                    <line x1="99.45" y1="4" x2="4" y2="75.39" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/>
                </g>
            </g>
        </g>
    </svg>
</template>
