<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 196.09 202.21">
        <g stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" data-name="Layer 1">
            <path fill="none" d="M4 89.52h188.09v108.7H4z"/>
            <path fill="#fff" d="M133.49 198.21H61.66V75.81l34.02-24.57 37.81 24.57v122.4z"/>
            <path fill="none" d="M95.68 51.24V4M95.68 13.9h39.97v20.79H95.68z"/>
            <circle cx="95.68" cy="105.58" r="19.14" fill="none"/>
            <path fill="none" d="M81.39 154.73h33.43v43.48H81.39zM95.68 96.04v9.54h-13.7M18.54 108.9h8.98M38.47 108.9h8.98M18.54 124.72h8.98M38.47 124.72h8.98M18.54 140.55h8.98M38.47 140.55h8.98M18.54 156.38h8.98M38.47 156.38h8.98M148.78 108.9h8.98M168.71 108.9h8.98M148.78 124.72h8.98M168.71 124.72h8.98M148.78 140.55h8.98M168.71 140.55h8.98M148.78 156.38h8.98M168.71 156.38h8.98"/>
        </g>
    </svg>
</template>
