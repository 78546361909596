<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172.25 203.69">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <g>
                    <path d="M130.3,174.13A72.05,72.05,0,0,1,18.69,180a3,3,0,0,1,4.43-4,66.06,66.06,0,0,0,102.36-5.37,6.3,6.3,0,0,0-6-9.93,6.23,6.23,0,0,0-4.11,2.5A53.53,53.53,0,1,1,48.64,83.48a6.29,6.29,0,1,0-5.52-11.31,66.09,66.09,0,0,0-26.93,94.7,3,3,0,1,1-5.05,3.2A72.08,72.08,0,0,1,40.5,66.8a12.27,12.27,0,0,1,10.76,22,47.55,47.55,0,1,0,59.24,70.79,12.27,12.27,0,0,1,19.8,14.49Z"/>
                    <path d="M84.9,0A19.1,19.1,0,1,0,104,19.1,19.12,19.12,0,0,0,84.9,0Zm0,32.22A13.12,13.12,0,1,1,98,19.1,13.13,13.13,0,0,1,84.9,32.22Z"/>
                    <path d="M171.49,167.19l-5.27-14.12.05,0-10.33-27.54a18.23,18.23,0,0,0-17.07-11.92H103.08V91.27h33.24a12.29,12.29,0,1,0,0-24.58H103.08V63.55a18.18,18.18,0,1,0-36.36,0V120A18.19,18.19,0,0,0,84.9,138.16h41a12.27,12.27,0,0,1,11.47,8l11.07,29.48A12.29,12.29,0,0,0,160,183.73a12.47,12.47,0,0,0,4.24-.75,12.31,12.31,0,0,0,7.3-15.79Zm-5.81,6.9a6.31,6.31,0,0,1-11.66-.5L143,144.11a18.24,18.24,0,0,0-17.07-11.93h-41A12.21,12.21,0,0,1,72.7,120V63.55a12.2,12.2,0,1,1,24.4,0v6.13a3,3,0,0,0,3,3h36.23a6.31,6.31,0,0,1,0,12.62H100.09a3,3,0,0,0-3,3v28.3a3,3,0,0,0,3,3h38.78a12.24,12.24,0,0,1,11.46,8L160.6,155l-.06,0,5.34,14.3A6.25,6.25,0,0,1,165.68,174.09Z"/>
                </g>
            </g>
        </g>
    </svg>
</template>
