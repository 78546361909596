<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="21.14" height="18.47" viewBox="0 0 21.14 18.47">
        <g id="alert-triangle" transform="translate(0.775 0.75)">
            <path id="Path_433" data-name="Path 433" d="M9.743,3.8,1.8,17.055a1.875,1.875,0,0,0,1.6,2.812h15.88a1.875,1.875,0,0,0,1.6-2.812L12.949,3.8a1.875,1.875,0,0,0-3.206,0Z" transform="translate(-1.552 -2.897)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <g id="Group_3444" data-name="Group 3444" transform="translate(-0.654 -0.566)">
                <line id="Line_196" data-name="Line 196" y2="4" transform="translate(10.448 6.103)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                <line id="Line_197" data-name="Line 197" x2="0.01" transform="translate(10.448 14.103)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            </g>
        </g>
    </svg>
</template>
