<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.25 16.25">
      <g data-name="Group 6813" transform="translate(-5.375 -4.375)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25">
        <rect data-name="Rectangle 978" width="13" height="13" rx="2" transform="translate(6 7)"/>
        <path data-name="Line 132" d="M16 5v4"/>
        <path data-name="Line 133" d="M9 5v4"/>
        <path data-name="Line 134" d="M6 12h13"/>
      </g>
    </svg>
</template>
