<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 201.387 201.387"
    fill="currentColor"
  >
    <path
      d="M72.351 174.297a3.422 3.422 0 00-3.572 3.246 3.413 3.413 0 003.246 3.572c19.442.928 29.904 4.584 31.889 6.622-2.461 2.62-17.13 6.823-40.767 6.823-23.639 0-38.308-4.205-40.767-6.823 2.005-2.065 12.626-5.755 32.377-6.644a3.414 3.414 0 003.256-3.563c-.085-1.883-1.715-3.35-3.564-3.256-11.754.529-39.089 2.9-39.089 13.46 0 13.269 42.895 13.653 47.787 13.653 4.891 0 47.787-.384 47.787-13.653 0-10.464-26.982-12.883-38.583-13.437z"
    />
    <path
      d="M69.974 0H56.321a3.414 3.414 0 00-3.413 3.413V184.32a3.414 3.414 0 003.413 3.413h13.653a3.413 3.413 0 003.413-3.413V3.413A3.414 3.414 0 0069.974 0zm-3.413 180.907h-6.827V6.827h6.827v174.08z"
    />
    <path
      d="M184.314 53.362c-.724-.416-17.886-10.238-34.536-16.094-7.508-2.642-14.647-4.154-21.548-5.617-8.678-1.838-16.874-3.575-25.417-7.381C91.275 19.132 72.269 4.289 72.078 4.14a3.415 3.415 0 00-5.519 2.687v98.987a3.413 3.413 0 002.799 3.357c.205.038 1.946.338 5.07.338 6.378 0 18.512-1.249 35.082-8.53 5.072-2.229 10.242-6.443 16.227-11.322 6.373-5.193 13.594-11.08 22.431-16.014 16.727-9.339 35.084-13.962 35.267-14.008a3.414 3.414 0 00.879-6.273zm-39.472 14.319c-9.359 5.224-16.828 11.312-23.415 16.683-5.6 4.565-10.436 8.508-14.66 10.363-16.623 7.305-28.322 8.052-33.379 7.931h.002V13.693c6.743 4.98 18.261 13.08 26.648 16.816 9.201 4.098 18.137 5.992 26.779 7.823 6.69 1.418 13.607 2.884 20.698 5.378 9.619 3.383 19.649 8.272 26.175 11.665-7.213 2.319-18.226 6.376-28.848 12.306z"
    />
  </svg>
</template>
