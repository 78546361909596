<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g transform="translate(1 1)">
      <circle class="a" cx="9" cy="9" r="9"
              fill="#fff" stroke="#f5f5f5" stroke-width="2px"/>
      <g transform="translate(1.375)">
        <path class="b" d="M9.074,0h0a9,9,0,0,1,0,18,9.021,9.021,0,0,1-7.7-4.443" transform="translate(-1.375)"
              fill="rgba(255, 255, 255, 0)" stroke="#4f98ca" stroke-width="2px"/>
      </g>
    </g>
  </svg>
</template>
