<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00012 15.9999L4.00012 15.9999L4.00012 10.9999"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.0001 6.99995L4.00013 15.9999"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
    />
    <path
      d="M10.9999 3.99995L15.9999 3.99995V8.99995"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.9999 8.99995L15.9999 3.99995"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
    />
  </svg>
</template>
