<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    fill="currentColor"
  >
    <path
      d="M504.485 88.172H7.515A7.515 7.515 0 000 95.687v320.626a7.515 7.515 0 007.515 7.515h496.971a7.515 7.515 0 007.515-7.515V95.687a7.516 7.516 0 00-7.516-7.515zM384.751 408.798H15.029V103.202h369.722v305.596zm112.22 0h-97.19V103.202h97.19v305.596z"
    />
    <path
      d="M352.188 152.297h-39.577a7.515 7.515 0 100 15.03h32.063v177.346H55.108V167.327H276.54a7.515 7.515 0 100-15.03H47.593a7.515 7.515 0 00-7.515 7.515v192.376a7.515 7.515 0 007.515 7.515h304.595a7.515 7.515 0 007.515-7.515V159.812a7.515 7.515 0 00-7.515-7.515zM448.376 296.579c-17.403 0-31.562 14.159-31.562 31.562s14.159 31.562 31.562 31.562 31.562-14.159 31.562-31.562c-.001-17.403-14.159-31.562-31.562-31.562zm-16.533 31.562c0-9.116 7.417-16.532 16.532-16.532 1.777 0 3.487.29 5.093.812l-20.814 20.814a16.485 16.485 0 01-.811-5.094zm16.533 16.532c-1.777 0-3.487-.29-5.093-.812l20.814-20.814a16.44 16.44 0 01.812 5.093c-.001 9.117-7.417 16.533-16.533 16.533zM448.376 224.438c-17.403 0-31.562 14.159-31.562 31.562s14.159 31.562 31.562 31.562 31.562-14.159 31.562-31.562-14.159-31.562-31.562-31.562zM431.843 256c0-9.116 7.417-16.532 16.532-16.532 1.777 0 3.487.29 5.093.812l-20.814 20.814a16.485 16.485 0 01-.811-5.094zm16.533 16.532c-1.777 0-3.487-.29-5.093-.812l20.814-20.814a16.44 16.44 0 01.812 5.093c-.001 9.117-7.417 16.533-16.533 16.533z"
    />
    <circle cx="432.344" cy="159.812" r="7.515" />
    <circle cx="464.908" cy="159.812" r="7.515" />
    <circle cx="432.344" cy="183.859" r="7.515" />
    <circle cx="464.908" cy="183.859" r="7.515" />
    <circle cx="432.344" cy="207.906" r="7.515" />
    <circle cx="464.908" cy="207.906" r="7.515" />
    <path d="M219.93 299.585a7.49 7.49 0 005.314-2.201l-5.314 2.201z" />
  </svg>
</template>
