<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18.361" height="18.361" viewBox="0 0 18.361 18.361">
    <path class="a" d="M0,0H18.361V18.361H0Z"
          fill="none"/>
    <path class="b" d="M.32,8.726,2.25,6.75,8.668.366" transform="translate(7.361 2)"
          fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1.25px"/>
    <path class="c" d="M16.7,3,11.723,16.771a.421.421,0,0,1-.765,0L8.28,11.415,2.925,8.738a.421.421,0,0,1,0-.765L16.7,3"
          transform="translate(-0.629 -0.705)"
          fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1.25px" stroke-linejoin="round"/>
  </svg>
</template>
