<template>
  <svg width="11" height="11" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="#3498db">
      <rect x=".5" y=".5" width="4" height="4" rx="1"/>
      <rect x=".5" y="6.5" width="4" height="4" rx="1"/>
      <rect x="6.5" y=".5" width="4" height="4" rx="1"/>
      <rect x="6.5" y="6.5" width="4" height="4" rx="1"/>
    </g>
  </svg>
</template>
