<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="1 1 14 12">
    <g id="Component_292_1" data-name="Component 292 – 1" transform="translate(1.5 1.5)">
        <path id="Polygon_1" data-name="Polygon 1" d="M6.5,0,13,11H0Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <g id="Group_4539" data-name="Group 4539" transform="translate(-344 -513)">
          <rect id="Rectangle_1888" data-name="Rectangle 1888" width="1" height="3" rx="0.5" transform="translate(350 517)" fill="currentColor"/>
          <rect id="Rectangle_1889" data-name="Rectangle 1889" width="1" height="1" rx="0.5" transform="translate(350 521)" fill="currentColor"/>
        </g>
    </g>
  </svg>
</template>
